export function getMaxHeight(selectors) {
  let i, height = 0
  let selectorsLenght = selectors.length

  for (i=0; i<selectorsLenght; i++) {
    if (height < selectors[i].scrollHeight)
      height = selectors[i].scrollHeight
  }

  return height + 'px'
}
