//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'

import {
  View, StyleSheet, Home, Programmation, ProgrammationDetail, Publications, PublicationDetail, Partners,
  FormProject, FormApplication, Contact
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Vue standard __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {},
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {}
  }).init()

  // Page feuille de style __________
  Barba.BaseView.extend({
    namespace: 'styleSheetView',
    onEnter:          function() {},
    onEnterCompleted: function() { StyleSheet.initStyleSheet()  },
    onLeave:          function() { StyleSheet.leaveStyleSheet() },
    onLeaveCompleted: function() {}
  }).init()

  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()

  // Page programmation __________
  Barba.BaseView.extend({
    namespace: 'programmationView',
    onEnter:          function() {},
    onEnterCompleted: function() { Programmation.initProgrammation()  },
    onLeave:          function() { Programmation.leaveProgrammation() },
    onLeaveCompleted: function() {}
  }).init()

  // Page détail de la programmation __________
  Barba.BaseView.extend({
    namespace: 'programmationDetailView',
    onEnter:          function() {},
    onEnterCompleted: function() { ProgrammationDetail.initProgrammationDetail()  },
    onLeave:          function() { ProgrammationDetail.leaveProgrammationDetail() },
    onLeaveCompleted: function() {}
  }).init()

  // Page publications __________
  Barba.BaseView.extend({
    namespace: 'publicationsView',
    onEnter:          function() {},
    onEnterCompleted: function() { Publications.initPublications()  },
    onLeave:          function() { Publications.leavePublications() },
    onLeaveCompleted: function() {}
  }).init()

  // Page détail d'une publication __________
  Barba.BaseView.extend({
    namespace: 'publicationDetailView',
    onEnter:          function() {},
    onEnterCompleted: function() { PublicationDetail.initPublicationDetail()  },
    onLeave:          function() { PublicationDetail.leavePublicationDetail() },
    onLeaveCompleted: function() {}
  }).init()

  // Page partenaires __________
  Barba.BaseView.extend({
    namespace: 'partnersView',
    onEnter:          function() {},
    onEnterCompleted: function() { Partners.initPartners()  },
    onLeave:          function() { Partners.leavePartners() },
    onLeaveCompleted: function() {}
  }).init()

  // Page soumettre un projet d'exposition __________
  Barba.BaseView.extend({
    namespace: 'formProjectView',
    onEnter:          function() {},
    onEnterCompleted: function() { FormProject.initFormProject()  },
    onLeave:          function() { FormProject.leaveFormProject() },
    onLeaveCompleted: function() {}
  }).init()

  // Page soumettre sa candidature à une exposition de groupe __________
  Barba.BaseView.extend({
    namespace: 'formApplicationView',
    onEnter:          function() {},
    onEnterCompleted: function() { FormApplication.initFormApplication()  },
    onLeave:          function() { FormApplication.leaveFormApplication() },
    onLeaveCompleted: function() {}
  }).init()

  // Page contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()
}
