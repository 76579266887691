//---------------------\\
//---- Debug class ----\\
//---------------------\\

export class Debug {
  constructor(instance) {
    this.instance = instance.overlay
    this.instance.hasError = false
    this.error = ''

    this.init()
  }


  // Check for errors
  init() {
    if (this.instance.name === 'undefined')
      this.getMessage('missingName')
    else if (Object.keys(this.instance.click.buttons).length === 0 && Object.keys(this.instance.mouseover.buttons).length === 0 && Object.keys(this.instance.timeout.buttons).length === 0)
      this.getMessage('missingEvent')

    if (this.error !== '')
      this.instance.hasError = true
  }


  // Construct the error message
  getMessage(error) {
    let symbol = '!!!!!!!!!!!!!!!!!!'
    let obj = {
      missingName: 'You must provide a name to your overlay',
      missingEvent: 'You must provide at least one event among the following: click, mouseover or timeout',
    }

    if (this.instance.name !== 'undefined') {
      this.error = symbol + '\n!!! OVERLAY.JS !!! -> ' + 'The overlay ' + this.instance.name + ': ' + obj[error] + '\n' + symbol
    } else {
      this.error = symbol + '\n!!! OVERLAY.JS !!! -> ' + obj[error] + '\n' + symbol
    }
  }


  // Return if there is an error
  hasError() {
    return this.instance.hasError
  }


  // Return the error
  getError() {
    return this.error
  }
}
