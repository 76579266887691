import $ from 'jquery'
import {OBSERVER} from '../main'
import Dropzone from '../dropzone/dist/dropzone.js'

export function dropzoneExists(selector) {
  let elements = $(selector).find('.dz-default')
  return elements.length > 0
}

export function createZone(photoID) {
  if(!dropzoneExists('#artwork'+photoID)) {
    let myDropzone = new Dropzone('#artwork'+photoID, {
      paramName: 'image',
      dictDefaultMessage: 'Cliquez, ou glissez l\'image de votre oeuvre ici',
      dictFileTooBig: 'La taille du fichier ne doit pas excéder 5mo',
      dictInvalidFileType: 'Le fichier doit être de type .JPG ou .JPEG',
      dictMaxFilesExceeded: 'Vous ne pouvez pas ajouter plus d\'une image',
      previewTemplate: $('.template').html(),
      url: 'artwork/image',
      maxFiles: 1,
      acceptedFiles: 'image/jpeg',
      maxFilesize: 5,

      success: function (File, response) {
        $(File.previewElement).data('fileID', response.fileID)
        $(File.previewElement).data('fileHash', response.fileHash)
        const filenameElement = document.getElementById('artwork_filename'+photoID)
        const urlElement = document.getElementById('artwork_url'+photoID)
        filenameElement.value = response.filename
        urlElement.value = response.fileURL
      },

      removedfile: function (File) {
        let $previewElmt = $(File.previewElement)
        let request = $.request('Formlog::onRemoveFile', {
          data: {
            fileID: $previewElmt.data('fileID'),
            fileHash: $previewElmt.data('fileHash'),
          },
          redirect: false
        })

        request.then(function () {
          const filenameElement = document.getElementById('artwork_filename'+photoID)
          const urlElement = document.getElementById('artwork_url'+photoID)
          filenameElement.value = ''
          urlElement.value = ''
          $previewElmt.remove()
        })
      },

      sending: function (file, xhr, formData) {
        formData.append('payload',
          JSON.stringify({_session_key: $('#_session_key').val()})
        )
      }
    })
  }
}

export function clearInput() {
  let i
  let btn = document.getElementsByClassName('clearInput')
  let btnLength = btn.length

  for (i = 0; i < btnLength; i++) {
    btn[i].addEventListener('click', (e) => {
      let input = e.currentTarget.parentElement.getElementsByTagName('input')[0]
      input.value = ''
      input.focus()
      input.parentElement.classList.remove('notEmpty')
      input.classList.remove('valid')
    })
  }
}

export function checkInput() {
  let i
  let input = document.querySelectorAll('input')
  let inputLength = input.length

  for (i = 0; i < inputLength; i++) {
    input[i].addEventListener('input', function (e) {
      if (e.target.value.length >= 1) {
        e.target.classList.add('notEmpty')
      } else {
        e.target.parentElement.classList.remove('notEmpty')
      }
    })
  }
}

export function changeLabelUploadApplication() {
  $('input[type=file]').off('change')
  $('input[type=file]').on('change', function () {
    $(this).next().next('label').find('span').text($(this).val().split(/(\\|\/)/g).pop())
  })
}

export function changeLabelUpload() {
  $('input[type=file]').off('change')
  $('input[type=file]').on('change', function () {
    $(this).next('label').find('span').text($(this).val().split(/(\\|\/)/g).pop())
  })
}

export function textareaHeight() {
  OBSERVER.add('scrollToBlock', 'input', onInput, 'textarea')
  OBSERVER.on('scrollToBlock')

  function onInput(e) {
    e.currentTarget.style.height = '5px'
    e.currentTarget.style.height = (e.currentTarget.scrollHeight) + 'px'
  }
}

export function getMessages() {
  if ($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please choose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.'
    }
  }
}

export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids())
        // eslint-disable-next-line semi
        return;

      let name = $(validator.errorList[0].element)[0].name
      let subName = name.slice(0, -1)
      if(subName === 'artwork_filename') {
        console.log($(validator.errorList[0].element).parent().children())
        $('html, body').animate({
          scrollTop: $(validator.errorList[0].element).parent().offset().top - 50
        }, 500) 
      }
      else {
        if($(form)[0].currentTarget.id === 'f_project') {
          const nbProjectError = validator.errorList.find(item => {
            return item.element.name === 'nbProject'
          })
          if($(validator.errorList[0].element)[0].name == 'nbProject') {
            $('html, body').animate({
              scrollTop: $(nbProjectError.element).parent().offset().top - 50
            }, 500)
            console.log($(nbProjectError.element).parent())
          }else {
            $('html, body').animate({
              scrollTop: $(validator.errorList[0].element).offset().top - 50
            }, 500)
          }

        }
        else {
          $('html, body').animate({
            scrollTop: $(validator.errorList[0].element).offset().top - 50
          }, 500)
        }
      }
    },
    rules: rules,
    messages: messages,
    errorPlacement: function (error, element) {
      error.appendTo(element.parent().find('p.errorMessage'))
    },
    submitHandler: function () {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function () {
    $(form).submit()
  })
}

export function documentOnKeyup(e) {
  // if there is a timeout, clearIt !
  if (document.firedDelayedInput !== false) {
    clearTimeout(document.firedDelayedInput)
    document.firedDelayedInput = false// prepare var to get the timeout function
  }
  let target = e.target
  // if target
  if (target.matches('input[data-request][data-blk-track-input]')) {
    // recupération du conteneur d’utilisateur
    let userBlock = target.closest('.infoUser')
    // récupération des inputs, handlerOctober et rowId
    let inputList = userBlock.querySelectorAll('.inputField input')
    let octoberHandle = target.dataset.request
    let rowId = target.dataset.rowId
    let timeOut = target.dataset.blkTrackInput // Time to wait the keyboard before send request
    // construction du payload
    let payload = {
      'rowId': rowId
    }
    inputList.forEach((item) => {
      payload[item.name] = item.value
    })

    document.firedDelayedInput = setTimeout(() => {

      setTimeout(function () {
        window.UI.ajaxWaiterOn($('a.delete,#payment-btn'))
      }, 1)

      // send to october !
      $.request(octoberHandle, {
        data: payload, // le payload dans sa face !!!
        loading: $.oc.stripeLoadIndicator,
        complete: () => window.UI.ajaxWaiterOff()
      })
      document.firedDelayedInput = false // reset the timeout tracking
    }, timeOut)
  }
}

export function payMask() {
  $('#f_payment_zip').mask('A0A 0A0')
  $('#f_payment_cardNumber').mask('0000 0000 0000 0000')
  $('#f_payment_expirationMonth').mask('00')
  $('#f_payment_expirationYear').mask('0000')
  $('#f_payment_ccv').mask('000')
}

export function formatCodePostal(element) {
  $(element).mask('ABA BAB', {
    'translation': {
      A: {pattern: /[A-Za-z]/},
      B: { pattern: /[0-9]/}
    }
  })
}

export function contactMask() {
  $('#f_contact_phone').mask('000 000-0000')
}

function UpdatePartial(formid, data) {
  for (var key in data) {
    $(key).html(data[key])
  }
  $('html, body').animate({
    scrollTop: $(formid).offset().top
  }, 200, 'easeInOutExpo')
}

export function formContact() {
  validationEmail()
  formatPhone('#f_contact_phone')

  var m = getMessages()

  var rules = {
    firstName: {required: true},
    lastName: {required: true},
    email: {required: true, email: true},
    phone: {required: true, minlength: 14},
    message: {required: true},
    subject: {required: true},
    cookie: {required: true}
  }

  var messages = {
    firstName: {required: m.required},
    lastName: {required: m.required},
    email: {required: m.required, email: m.email},
    phone: {required: m.required, minlength: m.phone},
    message: {required: m.required},
    subject: {required: m.required},
    cookie: {required: m.required}
  }

  formValidation('#f_contact', '#f_contact_submit', rules, messages)
}

export function validationEmail() {
  $.validator.addMethod('courriel', function (value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  })
}

export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}

export function formProjectExposition() {
  validationEmail()
  formatPhone('#f_project_phone')
  formatCodePostal('#f_project_postalCode')

  var m = getMessages()

  var rules = {
    firstName: {required: true},
    lastName: { required: true},
    email: {required: true, email: true},
    phone: {required: true, minlength: 14},
    postalCode: {required: true},
    address: {required: true},
    province: {required: true},
    town: {required: true},
    titreExpo: {required: true},
    descriptionExpo: {required: true},
    projectSelect: {required: true},
    cv: {required: true},
    demarche: {required: true},
    nbProject: {equalToTen: true}
  }

  var messages = {
    firstName: {required: m.required},
    lastName: {required: m.required},
    email: {required: m.required, email: m.email},
    phone: {required: m.required, minlength: m.phone},
    address: {required: m.required},
    province: {required: m.required},
    postalCode: {required: m.required,postale: m.postale},
    town: {required: m.required},
    titreExpo: {required: m.required},
    descriptionExpo: {required: m.required},
    projectSelect: {required: m.required},
    cv: {required: m.required},
    demarche: {required: m.required}
  }

  const nbArtwork = document.getElementById('nbProject')
  const btnAddArtwork = document.getElementById('btnAddArtWork')
  const btnRemoveArtWork = document.getElementById('btnRemoveArtWork')

  btnAddArtwork.addEventListener('click', e => {
    e.preventDefault()

    if(nbArtwork.value < 10) {
      nbArtwork.value++
      btnAddArtwork.classList.remove('hide')
    }

    if(nbArtwork.value >= 10)
      e.target.classList.add('hide')

    if(nbArtwork.value > 1)
      btnRemoveArtWork.classList.remove('hide')
  })

  btnRemoveArtWork.addEventListener('click', e => {
    e.preventDefault()

    if(nbArtwork.value !== 1) {
      let lastAddedArtworkID = `projet${nbArtwork.value}`
      let lastAddedArtwork = document.getElementById(lastAddedArtworkID)
      let jlastAddedArtwork = $(lastAddedArtwork)
      $('html, body').animate({
        scrollTop: $(jlastAddedArtwork).prev().offset().top - 15
      }, 200)
      jlastAddedArtwork.remove()
      removeArtworkRule(lastAddedArtworkID)
      nbArtwork.value--
    }

    if(nbArtwork.value <= 1)
      btnRemoveArtWork.classList.add('hide')

    if(nbArtwork.value < 10) {
      btnAddArtwork.classList.remove('hide')
      btnRemoveArtWork.classList.remove('hide')
    }
  })

  attachObserver('f_project', nbArtwork)

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5mo')

  $.validator.addMethod('equalToTen', function (value, element) {
    return this.optional(element) || (parseFloat(value) === 10)
  }, 'Afin d\'envoyer le formulaire, veuillez soumettre un total de 10 oeuvres.')

  for (let i = 1; i <= 10; i++) {
    rules['price' + i] = {required: true}
    rules['title' + i] = {required: true}
    rules['technique' + i] = {required: true}
    rules['dimensions' + i] = {required: true}
    rules['year' + i] = {required: true}
    rules['incluse' + i] = {required: true} 
    rules['artwork_filename' + i] = {required: true}
    
    messages['price' + i] = {required: m.required}
    messages['title' + i] = {required: m.required}
    messages['technique' + i] = {required: m.required}
    messages['dimensions' + i] = {required: m.required}
    messages['year' + i] = {required: m.required}
    messages['incluse' + i] = {required: m.required}
    messages['artwork_filename' + i] = {required: m.required}
  }

  formValidation('#f_project', '#f_project_submit', rules, messages, function () {
    let form = document.getElementById('f_project')
    $.oc.stripeLoadIndicator.show()
    $('#f_project_submit').addClass('oc-loading')

    var formData = new FormData(form)

    $.ajax({
      url: '/projet-exposition',
      headers: {
        'X-OCTOBER-REQUEST-HANDLER': 'onSubmitFormulaire',
      },
      type: 'POST',
      data: formData,
      async: true,
      success: function (data) {
        UpdatePartial('#form-success', data)
        $.oc.stripeLoadIndicator.hide()
        $('#f_project_submit').removeClass('oc-loading')
      },
      cache: false,
      contentType: false,
      processData: false,
      dataType: 'json'
    })

  })
}

export function formProjectApplication() {
  const nbArtwork = document.getElementById('nbProject')
  const btnAddArtwork = document.getElementById('btnAddArtWork')
  const btnRemoveArtWork = document.getElementById('btnRemoveArtWork')

  validationEmail()
  formatPhone('#f_application_phone')

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')

  var m = getMessages()
  var rules = {
    firstName: {required: true},
    lastName: {required: true},
    email: {required: true, email: true},
    phone: { required: true, minlength: 14},
    postalCode: {required: true},
    address: {required: true},
    province: {required: true},
    town: {required: true},
    titreExpo: {required: true},
    descriptionExpo: {required: true},
    projectSelect: {required: true},
    cv: {required: true},
    demarche: {required: true},
    technique: {required: true},
    application_work: {required: true},
  }

  var messages = {
    firstName: { required: m.required},
    lastName: {required: m.required},
    email: {required: m.required,email: m.email},
    phone: {required: m.required, minlength: m.phone},
    address: {required: m.required},
    province: {required: m.required},
    postalCode: {required: m.required,postale: m.postale},
    town: {required: m.required},
    titreExpo: {required: m.required},
    descriptionExpo: {required: m.required},
    projectSelect: {required: m.required},
    cv: {required: m.required},
    demarche: { required: m.required},
    technique: {required: m.required},
    application_work: {required: m.required},
  }

  btnAddArtwork.addEventListener('click', e => {
    e.preventDefault()

    if (nbArtwork.value <= 5) {
      nbArtwork.value++
      btnAddArtwork.classList.remove('hide')
    }

    if (nbArtwork.value >= 5)
      e.target.classList.add('hide')

    if(nbArtwork.value > 1)
      btnRemoveArtWork.classList.remove('hide')
  })

  btnRemoveArtWork.addEventListener('click', e => {
    e.preventDefault()

    if(nbArtwork.value !== 1) {
      let lastAddedArtworkID = `projet${nbArtwork.value}`
      let lastAddedArtwork = document.getElementById(lastAddedArtworkID)
      let jlastAddedArtwork = $(lastAddedArtwork)
      $('html, body').animate({
        scrollTop: $(jlastAddedArtwork).prev().offset().top - 15
      }, 200)
      jlastAddedArtwork.remove()
      removeArtworkRule(lastAddedArtworkID)
      nbArtwork.value--
    }

    if(nbArtwork.value <= 1) 
      btnRemoveArtWork.classList.add('hide')

    if(nbArtwork.value < 5)

      btnAddArtwork.classList.remove('hide')

    if(nbArtwork.value > 1 && nbArtwork.value < 5) 
      btnRemoveArtWork.classList.remove('hide')
  })

  attachObserver('f_application' , nbArtwork)

  document.getElementById('f_application_submit').addEventListener('click', e => {
    const nbArtwork = document.getElementById('nbProject')
    for(let i = 1; i <= nbArtwork.value; i++)
      addArtworkRule(i)
  })

  formValidation('#f_application', '#f_application_submit', rules, messages, function () {
    let form = document.getElementById('f_application')
    $.oc.stripeLoadIndicator.show()
    $('#f_application_submit').addClass('oc-loading')
    var formData = new FormData(form)
    $.ajax({
      url: '/exposition-de-groupe',
      headers: {
        'X-OCTOBER-REQUEST-HANDLER': 'onSubmitFormulaire',
      },
      type: 'POST',
      data: formData,
      async: true,
      success: function (data) {
        UpdatePartial('#form-success', data)
        $.oc.stripeLoadIndicator.hide()
        $('#f_application_submit').removeClass('oc-loading')
      },
      cache: false,
      contentType: false,
      processData: false,
      dataType: 'json'
    })
  })
}

export function addArtworkRule(artworkID) {
  $('#projet'+artworkID).each(function() {
    $(this).find(`input[name!='artwork_url${artworkID}']`).each(function() {
      $(this).rules('add', {
        required: true,
        messages: {
          required: 'Ce champ est obligatoire.'
        }
      })
    })
  })
}

export function removeArtworkRule(artworkID) {
  $('#projet'+artworkID).each(function(){
    $(this).find(':input').each(function() {
      $(this).rules('remove')
    })
  })
}

export function attachObserver (formID, nbArtwork) {
  const targetNode = document.getElementById(formID)
  const config  = { attributes: true, childList: true, subtree: true}

  const callback = function(mutationsList, observer) {
    Array.from(document.querySelectorAll('.btnClearInput')).forEach(element => {
      element.addEventListener('click', e => {
        e.preventDefault()
        Array.from(e.target.parentElement.querySelectorAll('input')).forEach(input => {
          input.value = ''
        })
        if(e.target.parentElement.querySelector('.dz-complete') !== null)
          e.target.parentElement.querySelector('.dz-complete').lastElementChild.click()
      })
    })
    createZone(nbArtwork.value)
  }

  const observer = new MutationObserver(callback)
  observer.observe(targetNode, config)
}

export function formNewsLetter() {
  validationEmail()
  var m = getMessages()

  var rules = {
    email: {required: true, email: true}
  }

  var messages = {
    email: {required: m.required, email: m.email},
  }

  formValidation('#f_newsletter', '#f_newsletter_submit', rules, messages)
}