//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { SCROLLER, PARALLAX, SCROLLFIRE, OBSERVER } from './main.js'
import { Overlay } from './classes/Overlay/Overlay.js'
import { removeHoverOnMobile, hundredVH } from './functions/helper.js'
import { overlayMenu, overlayDropdown, overlayShare } from './functions/overlay.js'
import { polyfill } from './functions/polyfills.js'
import { clearInput, checkInput, textareaHeight, changeLabelUpload, changeLabelUploadApplication, formContact, formProjectExposition, formNewsLetter, formProjectApplication } from './functions/forms.js'
import { drawers, customSelect, dragAndSlide, sizeH1, scrollToBlock, eventsLoaded, activeFilter, addSlugToShare, manageRubriqueHash } from './functions/functions.js'
import { slickBanner, destroySlick } from './functions/slicks.js'
//-----------------------------------------------------------------------------------------------------------------

window.eventsLoaded = eventsLoaded
window.activeFilter = activeFilter

// Initialisation sur toutes les pages
export class View {
  static init() {
    polyfill()
    removeHoverOnMobile()
    SCROLLER.init()
    PARALLAX.init({ customScroll: true })
    SCROLLFIRE.init({ customScroll: true, offset: true, mobile: false })
    clearInput()
    hundredVH()
    checkInput()
    overlayMenu()
    overlayDropdown()
    changeLabelUpload()
    overlayShare()
    formNewsLetter()
  }

  static leave() {
    setTimeout(() => {
      OBSERVER.allOff()
      OBSERVER.removeAll()
      Overlay.destroyAll()
      SCROLLER.destroy()
      PARALLAX.destroy()
      SCROLLFIRE.destroy()
    }, 400)
  }
}


// Classe initialisant les scripts présents dans la page feuille de styles
export class StyleSheet extends View {
  static initStyleSheet() {
    this.init()
    drawers('.p_rubricsList')
    textareaHeight()
    customSelect()
    
    addSlugToShare()
    manageRubriqueHash()
  }

  static leaveStyleSheet() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
    slickBanner()
    sizeH1()
    scrollToBlock({ buttons: '#scrollDown', destination: '#scrollDestination' })
  }

  static leaveHome() {
    destroySlick('#slickTitles')
    destroySlick('#slickImages')
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page programmation
export class Programmation extends View {
  static initProgrammation() {
    this.init()
    dragAndSlide()
  }

  static leaveProgrammation() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page détail de la programmation
export class ProgrammationDetail extends View {
  static initProgrammationDetail() {
    this.init()
    sizeH1()
    
  }

  static leaveProgrammationDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page publications
export class Publications extends View {
  static initPublications() {
    this.init()
    dragAndSlide()
  }

  static leavePublications() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page détail d'une publication
export class PublicationDetail extends View {
  static initPublicationDetail() {
    this.init()
    dragAndSlide()
  }

  static leavePublicationDetail() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page partenaires
export class Partners extends View {
  static initPartners() {
    this.init()
  }

  static leavePartners() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page soumettre un projet d'exposition
export class FormProject extends View {
  static initFormProject() {
    this.init()
    customSelect()
    textareaHeight()
    formProjectExposition()
    changeLabelUploadApplication()
  }

  static leaveFormProject() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page soumettre sa candidature à une exposition de groupe
export class FormApplication extends View {
  static initFormApplication() {
    this.init()
    sizeH1()
    textareaHeight()
    formProjectApplication()
    changeLabelUploadApplication()
  }

  static leaveFormApplication() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page contact
export class Contact extends View {
  static initContact() {
    this.init()
    dragAndSlide()
    drawers('.p_rubricsList')
    textareaHeight()
    customSelect()
    
    addSlugToShare()
    manageRubriqueHash()
    formContact()
  }

  static leaveContact() {
    this.leave()
  }
}
