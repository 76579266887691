//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/Overlay/Overlay.js'
import { copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------

// Fonction gérant le dropdown
export function overlayDropdown() {
  new Overlay({
    name: 'Dropdown',
    structure: {
      openingClass: 'openingDropdown',
      closingClass: 'closingDropdown',
    },
    click: {
      buttons: {
        close: '#overlayDropdown .closeOverlay',
        toggle: '.btnDropdown',
      }
    },
    options: {
      speed: 800,
      goToSelector: 'html'
    }
  })
}

// Fonction gérant le menu mobile
export function overlayMenu() {
  new Overlay({
    name: 'Menu',
    structure: {
      openingClass: 'openingMenu',
      closingClass: 'closingMenu',
    },
    click: {
      buttons: {
        close: '#overlayMenu .closeOverlay',
        toggle: '.btnMenu',
      }
    },
    options: {
      speed: 800
    }
  })

  $('.groundFloor .btnSubLvl1').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    $('.groundFloor li.open').removeClass('open')
    $('.groundFloor').removeClass('oneIsActive')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() {
        $(chosen).parent().addClass('open')
        $('.groundFloor').addClass('oneIsActive')
      }, 0)
      $('.subLvl1', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction gérant l'overlay de partage
export function overlayShare() {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  new Overlay({
    name: 'Share',
    structure: {
      openingClass: 'openingShare',
      closingClass: 'closingShare',
    },
    click: {
      buttons: {
        open: '.btnShare, .share',
        close: '.closeOverlay, .p_blackScreen',
      }
    },
    options: {
      speed: 800,
    }
  })

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.shareBtn').click( function() {
    var attr = $(this).attr('data-url')
    if (typeof attr !== typeof undefined && attr !== false) {
      $('.medias-sociaux a').attr('data-url', attr)
    } else {
      $('.medias-sociaux a').attr('data-url', window.location.href)
    }
    $('#emailShareBtn').attr('href','mailto:?Subject=Maison et jardins Antoine-Lacombe&body=' + encodeURIComponent(document.domain+attr))

    return false
  })
}
