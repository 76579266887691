//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// ANCHOR slickBanner
// NOTE Création du slick de la bannière d'accueil
export function slickBanner() {
  $('#slickTitles').slick({
    arrows: false,
    dots: true,
    appendDots: '#slickDots',
    infinite: true,
    autoplay: true,
    draggable: true,
    adaptiveHeight: true,
    speed: 700,
    autoplaySpeed: 20000,
    fade: true,
    pauseOnHover: true,
    asNavFor: '#slickImages'
  })
  $('#slickImages').slick({
    arrows: false,
    dots: false,
    infinite: true,
    draggable: true,
    speed: 700,
    pauseOnHover: false,
    variableWidth: true,
    asNavFor: '#slickTitles'
  })

  $('#slickNext').on('click', () => {
    $('#slickImages').slick('slickNext')
  })

  fixSlickAnim('#slickImages')
}


// ANCHOR fixSlickAnim
// NOTE Fonction permettant de corriger l'animation d'une slide à une autre en mode infinite
function fixSlickAnim(slider) {
  $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let direction, slideCountZeroBased = slick.slideCount - 1

    if (nextSlide == currentSlide)
      direction = 'same'
    else if (Math.abs(nextSlide - currentSlide) == 1)
      direction = (nextSlide - currentSlide > 0) ? 'right' : 'left'
    else
      direction = (nextSlide - currentSlide > 0) ? 'left' : 'right'

    if (direction == 'right')
      $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')

    if (direction == 'left')
      $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
  })

  $(slider).on('afterChange', () => {
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
  })
}


// ANCHOR destroySlick
// NOTE Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 490 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
