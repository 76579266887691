//------------------------------\\
//---- MouseoverEvent class ----\\
//------------------------------\\

export class MouseoverEvent {

  constructor(instance) {
    if (instance !== undefined)
      this.instance = instance

    this.init()
  }


  init() {
    let i
    let doc = document

    // The open click event
    if (this.instance.overlay.mouseover.buttons.open !== undefined) {
      this.openButtons = doc.querySelectorAll(this.instance.overlay.mouseover.buttons.open)
      let openButtonsLength = this.openButtons.length
      for (i = 0; i < openButtonsLength; i++) {
        this['boundOpen' + i] = e => this.instance.open(e)
        this.openButtons[i].addEventListener('mouseover', this['boundOpen' + i], false)
      }
    }

    // The close click event
    if (this.instance.overlay.mouseover.buttons.close !== undefined) {
      this.closeButtons = doc.querySelectorAll(this.instance.overlay.mouseover.buttons.close)
      let closeButtonsLength = this.closeButtons.length
      for (i = 0; i < closeButtonsLength; i++) {
        this['boundClose' + i] = e => this.instance.close(e)
        this.closeButtons[i].addEventListener('mouseover', this['boundClose' + i], false)
      }
    }

    // The trigger click event
    if (this.instance.overlay.mouseover.buttons.trigger !== undefined) {
      this.triggerButtons = doc.querySelectorAll(this.instance.overlay.mouseover.buttons.trigger)
      let triggerButtonsLength = this.triggerButtons.length
      for (i = 0; i < triggerButtonsLength; i++) {
        this['boundTrigger' + i] = e => this.instance.trigger(e)
        this['boundResetTimeout' + i] = e => this.instance.resetTimeout(e)
        this.triggerButtons[i].addEventListener('mouseover', this['boundTrigger' + i], false)
        this.triggerButtons[i].addEventListener('mouseleave', this['boundResetTimeout' + i], false)
      }
    }
  }


  destroy() {
    let i

    // The open click event
    if (this.instance.overlay.mouseover.buttons.open !== undefined) {
      let openButtonsLength = this.openButtons.length
      for (i = 0; i < openButtonsLength; i++)
        this.openButtons[i].removeEventListener('mouseover', this['boundOpen' + i], false)
    }

    // The close click event
    if (this.instance.overlay.mouseover.buttons.close !== undefined) {
      let closeButtonsLength = this.closeButtons.length
      for (i = 0; i < closeButtonsLength; i++)
        this.closeButtons[i].removeEventListener('mouseover', this['boundClose' + i], false)
    }

    // The trigger click event
    if (this.instance.overlay.mouseover.buttons.trigger !== undefined) {
      let triggerButtonsLength = this.triggerButtons.length
      for (i = 0; i < triggerButtonsLength; i++) {
        this.triggerButtons[i].removeEventListener('mouseover', this['boundTrigger' + i], false)
        this.triggerButtons[i].removeEventListener('mouseleave', this['boundResetTimeout' + i], false)
      }
    }
  }
}
