//-----------------------\\
//---- Overlay class ----\\
//-----------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Class that create overlay (popup, sidepanel, others)
//
// new Overlay({
//   name: STRING['undefined'] -> The overlay's name
//   structure: {
//     id: STRING['overlay'+name] -> The overlay's id
//     selector: STRING['html'] -> The selector where the active Class will be added
//     activeClass: STRING['show'+id] -> The class used for the overlay's activation
//     genericClass: STRING['showOverlay'] -> The class that is always there when an overlay is active
//     openingClass: STRING['opening'] -> The class added to the selector during the opening
//     closingClass: STRING['closing'] -> The class added to the selector during the closing
//     dataset: STRING['section'] -> A data on the button to add the content as a class
//   },
//   events {
//     open: STRING['onOpenOverlay'] -> Event dispatched on open initialization
//     openComplete: STRING['onOpenCompleteOverlay'] -> Event dispatched on open completed
//     close: STRING['onClosingOverlay'] -> Event dispatched on close initialization
//     closeComplete: STRING['onClosingCompleteOverlay'] -> Event dispatched on close completed
//   },
//   click: {
//     buttons: {
//       open: STRING[undefined] -> Selector closing the overlay
//       close: STRING[undefined] -> Selector toggling the overlay
//       toggle: STRING[undefined] -> Selector opening the overlay
//       trigger: STRING[undefined] -> Selector triggering the overlay without opening or closing it
//       switch: STRING[undefined] -> Selector switching the overlay with another without removing noScroll
//     }
//   },
//   mouseover: {
//     delay: NUMBER[undefined] -> Delay before triggering the trigger
//     buttons: {
//       open: STRING[undefined] -> Selector closing the overlay
//       close: STRING[undefined] -> Selector toggling the overlay
//       trigger: STRING[undefined] -> Selector triggering the overlay without opening or closing it
//     }
//   },
//   timeout: {
//     delay: NUMBER[5000] -> The delay of the opening and closing animation
//     cookie: STRING[''] -> The cookie prevent the event timeout to be activated
//     buttons: {
//       close: STRING[undefined] -> Selector toggling the overlay
//     }
//   },
//   animations : [
//     {
//       selector: STRING[undefined] -> The selector for the animation
//       addTransition: BOOLEAN[undefined] -> Add a transition on the element
//       styles: [
//         {
//           property: STRING[undefined] -> The property to add to the selector given
//           value: STRING[undefined] -> The value to this property
//           easing: STRING[undefined] -> The name of the easing function
//         }
//       ]
//     }
//   ],
//   options {
//     speed: NUMBER[600] -> The speed of the opening and closing animation
//     opening: {
//       speed: NUMBER[undefined] -> The speed of the opening animation
//     },
//     closing {
//       speed: NUMBER[undefined] -> The speed of the closing animation
//     },
//     goToSelector: BOOLEAN[false] -> Go back to the top of the page at the overlay's opening
//     global = BOOLEAN[false] -> Add the overlay's instance to a global variable (window)
//     noScroll: BOOLEAN[true] -> Remove to the user the right of scrolling in the web page
//     closeOnResize: BOOLEAN[true] -> Close the overlay on the resizing of the browser window
//     override: BOOLEAN[FALSE] -> Other overlay can open over this one
//   }
// })
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { ClickEvent } from './CustomEvents/ClickEvent.js'
import { MouseoverEvent } from './CustomEvents/MouseoverEvent.js'
import { TimeoutEvent } from './CustomEvents/TimeoutEvent.js'
import { ResizeEvent } from './CustomEvents/ResizeEvent.js'
import { Debug } from './Debug.js'
import { getElementOffset } from './CustomFunctions/getElementOffset.js'
import { getScrollbarWidth } from './CustomFunctions/getScrollbarWidth.js'
import { getObjectSize } from './CustomFunctions/getObjectSize.js'
import { getMaxHeight } from './CustomFunctions/getMaxHeight.js'
import { getEasingCubic } from './CustomFunctions/getEasingCubic.js'
//-----------------------------------------------------------------------------------------------------------------

export class Overlay {

  // ----------------------------------------------------------------------------------------------------------------------------
  // Set all default value of the class
  // ----------------------------------------------------------------------------------------------------------------------------
  constructor(object) {
    this.overlay = object

    if (this.overlay.name === undefined) this.overlay.name = 'undefined'
    if (this.overlay.structure === undefined) this.overlay.structure = {}
    if (this.overlay.structure.id === undefined) this.overlay.structure.id = 'overlay' + this.overlay.name.charAt(0).toUpperCase() + this.overlay.name.slice(1)
    if (this.overlay.structure.selector === undefined) this.overlay.structure.selector = 'html'
    if (this.overlay.structure.activeClass === undefined) this.overlay.structure.activeClass = 'show' + this.overlay.structure.id.charAt(0).toUpperCase() + this.overlay.structure.id.slice(1)
    if (this.overlay.structure.genericClass === undefined) this.overlay.structure.genericClass = 'showOverlay'
    if (this.overlay.structure.openingClass === undefined) this.overlay.structure.openingClass = 'opening'
    if (this.overlay.structure.closingClass === undefined) this.overlay.structure.closingClass = 'closing'
    if (this.overlay.structure.dataset === undefined) this.overlay.structure.dataset = 'section'

    if (this.overlay.events === undefined) this.overlay.events = {}
    if (this.overlay.events.open === undefined) this.overlay.events.open = 'onOpenOverlay'
    if (this.overlay.events.openComplete === undefined) this.overlay.events.openComplete = 'onOpenCompleteOverlay'
    if (this.overlay.events.close === undefined) this.overlay.events.close = 'onCloseOverlay'
    if (this.overlay.events.closeComplete === undefined) this.overlay.events.closeComplete = 'onCloseCompleteOverlay'

    if (this.overlay.animations === undefined) this.overlay.animations = []

    if (this.overlay.click === undefined) this.overlay.click = {}
    if (this.overlay.click.buttons === undefined) this.overlay.click.buttons = {}

    if (this.overlay.mouseover === undefined) this.overlay.mouseover = {}
    if (this.overlay.mouseover.buttons === undefined) this.overlay.mouseover.buttons = {}

    if (this.overlay.timeout === undefined) this.overlay.timeout = {}
    if (this.overlay.timeout.delay === undefined) this.overlay.timeout.delay = 5000
    if (this.overlay.timeout.cookie === undefined) this.overlay.timeout.cookie = ''
    if (this.overlay.timeout.buttons === undefined) this.overlay.timeout.buttons = {}

    if (this.overlay.options === undefined) this.overlay.options = {}
    if (this.overlay.options.speed === undefined) this.overlay.options.speed = 600
    if (this.overlay.options.opening === undefined) this.overlay.options.opening = {}
    if (this.overlay.options.closing === undefined) this.overlay.options.closing = {}
    if (this.overlay.options.goToSelector === undefined) this.overlay.options.goToSelector = ''
    if (this.overlay.options.global === undefined) this.overlay.options.global = false
    if (this.overlay.options.noScroll === undefined) this.overlay.options.noScroll = 'true'
    if (this.overlay.options.closeOnResize === undefined) this.overlay.options.closeOnResize = 'true'
    if (this.overlay.options.override === undefined) this.overlay.options.override = false

    this.debug()
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Debug the object received before initializing the class
  // ----------------------------------------------------------------------------------------------------------------------------
  debug() {
    this.overlay.debug = new Debug(this)
    if(this.overlay.debug.hasError())
      console.log(this.overlay.debug.getError())
    else
      this.init()
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Initialization of the overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  init() {
    // Variable assignment
    this.dataset
    this.status = 'close'
    this.delay
    this.animations = []
    this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    // Active the custom scroll if it's not Internet explorer
    this.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)
    if (this.overlay.options.customScroll === undefined && !this.isIE)
      this.overlay.options.customScroll = true
    else
      this.overlay.options.customScroll = false

    // Creation of the callback events
    this.openEvent = new CustomEvent(this.overlay.events.open)
    this.openCompleteEvent = new CustomEvent(this.overlay.events.openComplete)
    this.closeEvent = new CustomEvent(this.overlay.events.close)
    this.closeCompleteEvent = new CustomEvent(this.overlay.events.closeComplete)

    // Put the overlay in the window object
    if (this.overlay.options.global)
      window.overlay[this.overlay.name] = this

    // Put the overlay in a global array
    Overlay.instances.push(this)

    // Prepare the animations
    this.prepareAnimations()

    // Enable the close on resize
    if (this.overlay.options.closeOnResize)
      this.resizeEvent = new ResizeEvent(this)

    // Dispatch the events for the overlay
    if (getObjectSize(this.overlay.click.buttons) > 0)
      this.clickEvent = new ClickEvent(this)

    if (getObjectSize(this.overlay.mouseover.buttons) > 0)
      this.mouseoverEvent = new MouseoverEvent(this)

    if (getObjectSize(this.overlay.timeout.buttons) > 0 && document.querySelectorAll('#' + this.overlay.structure.id).length != 0)
      this.timeoutEvent = new TimeoutEvent(this)
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Open the overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  open() {

    // Variable assignment
    let doc = document
    let html = doc.querySelector('html')
    let selector = doc.querySelector(this.overlay.structure.selector)
    let speed = (this.overlay.options.opening.speed !== undefined ? this.overlay.options.opening.speed : this.overlay.options.speed)

    // If it's not opened, open it
    if (this.status === 'close') {

      // Switch the status of the overlay to opened
      this.status = 'open'

      // start opening animation
      if(this.isAnimations)
        this.animate()

      // Cancel the closing for the opening
      if (selector.classList.contains(this.overlay.structure.closingClass)) {
        clearTimeout(this.closingTimeout)
        if (!this.overlay.options.override)
          selector.classList.remove(this.overlay.structure.genericClass)
        selector.classList.remove(this.overlay.structure.activeClass)
        selector.classList.remove(this.overlay.structure.closingClass)
      }

      // Scroll to the selector given if there's one
      if (this.overlay.options.goToSelector !== '')
        html.scrollTop = getElementOffset(this.overlay.options.goToSelector).top

      // Add the no scroll if the option is on
      if (this.overlay.options.noScroll && !html.classList.contains('noCustomScroll'))
        this.addNoScroll()

      // Dispatch a custom event on the opening
      dispatchEvent(this.openEvent)

      // No generic class if the overlay can be opened with another one
      if (!this.overlay.options.override)
        selector.classList.add(this.overlay.structure.genericClass)

      // Add active and opening classes
      selector.classList.add(this.overlay.structure.openingClass)
      selector.classList.add(this.overlay.structure.activeClass)

      // Remove the opening class when the opening is done
      this.openingTimeout = setTimeout(() => {
        dispatchEvent(this.openCompleteEvent)
        selector.classList.remove(this.overlay.structure.openingClass)
      }, speed)
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Close the overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  close(e, switchOverlay=false) {

    // Variable assignment
    let doc = document
    let selector = doc.querySelector(this.overlay.structure.selector)
    let speed = (this.overlay.options.closing.speed !== undefined ? this.overlay.options.closing.speed : this.overlay.options.speed)

    // If it's not closed, close it
    if (this.status === 'open') {

      // Switch the status of the overlay to opened
      this.status = 'close'

      // Remove the dataset trigger on close
      if (this.overlay.structure.dataset !== undefined && !switchOverlay)
        selector.dataset[this.overlay.structure.dataset] = ''

      // start closing animation
      if(this.isAnimations)
        this.animate()

      // Cancel the opening for the closing
      if (selector.classList.contains(this.overlay.structure.openingClass)) {
        clearTimeout(this.openingTimeout)
        selector.classList.remove(this.overlay.structure.openingClass)
      }

      // Remove the no scroll if the option is on
      if (this.overlay.options.noScroll && !switchOverlay)
        this.removeNoScroll()

      // Dispatch a custom event on the closing
      dispatchEvent(this.closeEvent)

      // Add the closing class
      selector.classList.add(this.overlay.structure.closingClass)

      // Remove the closing class when the closing is done
      this.closingTimeout = setTimeout(() => {
        dispatchEvent(this.closeCompleteEvent)
        if(!switchOverlay && !this.overlay.options.override)
          selector.classList.remove(this.overlay.structure.genericClass)
        selector.classList.remove(this.overlay.structure.activeClass)
        selector.classList.remove(this.overlay.structure.closingClass)
      }, speed)
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Trigger the overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  trigger(e) {
    let doc = document
    let selector = doc.querySelector(this.overlay.structure.selector)
    let targetDataset = e.currentTarget.dataset[this.overlay.structure.dataset]

    // if the target has a dataset to trigger and the overlay is opened
    if (targetDataset !== undefined) {
      if (selector.classList.contains(this.overlay.structure.activeClass)) {
        if (e.currentTarget.dataset[this.overlay.structure.dataset] === selector.dataset[this.overlay.structure.dataset] && this.overlay.click.buttons.trigger !== undefined) {
          this.close(e)
          selector.dataset[this.overlay.structure.dataset] = ''
        } else {
          if (this.overlay.mouseover.delay !== undefined && selector.dataset[this.overlay.structure.dataset] !== '') {
            this.delay = setTimeout(() => {
              selector.dataset[this.overlay.structure.dataset] = targetDataset
            }, this.overlay.mouseover.delay)
          } else {
            selector.dataset[this.overlay.structure.dataset] = targetDataset
          }
        }
      } else {
        selector.dataset[this.overlay.structure.dataset] = ''
      }
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Toggle the opening and the closing
  // ----------------------------------------------------------------------------------------------------------------------------
  toggle(e) {
    let doc = document
    let selector = doc.querySelector(this.overlay.structure.selector)
    let hasActiveClass = selector.classList.contains(this.overlay.structure.activeClass)
    let hasClosingClass = selector.classList.contains(this.overlay.structure.closingClass)

    if (!hasActiveClass || hasClosingClass)
      this.open(e)
    else
      this.close(e)
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Switch to another overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  switch(e) {
    let selector = document.querySelector(this.overlay.structure.selector)

    if (selector.classList.contains(this.overlay.structure.activeClass))
      this.close(e, true)
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Close the overlay on resize if the option is on
  // ----------------------------------------------------------------------------------------------------------------------------
  resize() {
    let newWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (newWidth != this.windowWidth) {
      this.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      this.close()
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Animate some properties given in the Overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  prepareAnimations() {
    // Variable assignment
    let i, j, stylesLength
    let doc = document
    let animationslength = this.overlay.animations.length

    if (animationslength > 0) {
      this.isAnimations = true
      for (i=0; i<animationslength; i++) {
        this.overlay.animations[i].selector = doc.querySelectorAll(this.overlay.animations[i].selector)
        stylesLength = this.overlay.animations[i].styles.length

        for (j=0; j<stylesLength; j++) {
          if (this.overlay.animations[i].styles[j].property === 'height' && this.overlay.animations[i].styles[j].value === '100%')
            this.overlay.animations[i].styles[j].value = getMaxHeight(this.overlay.animations[i].selector)

          if (this.overlay.animations[i].styles[j].easing !== undefined)
            this.overlay.animations[i].styles[j].easing = getEasingCubic(this.overlay.animations[i].styles[j].easing)
        }
      }
    } else {
      this.isAnimations = false
    }
  }



  // ----------------------------------------------------------------------------------------------------------------------------
  // Animate some properties given in the Overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  animate() {
    // Variable assignment
    let i, j, k, speed, selectorsLength, stylesLength, transitionString = ''
    let animationslength = this.overlay.animations.length

    if (this.status === 'open')
      speed = (this.overlay.options.opening.speed !== undefined ? this.overlay.options.opening.speed : this.overlay.options.speed)
    else
      speed = (this.overlay.options.opening.speed !== undefined ? this.overlay.options.opening.speed : this.overlay.options.speed)

    // All the animations object
    for (i=0; i<animationslength; i++) {
      let i_animation = this.overlay.animations[i]
      selectorsLength = i_animation.selector.length
      stylesLength = i_animation.styles.length

      // All the element of the querySelectorAll
      for (j=0; j<selectorsLength; j++) {
        let j_selector = i_animation.selector[j]

        // All the styles object
        for (k=0; k<stylesLength; k++) {
          j_selector.style[i_animation.styles[k].property] = this.status === 'open' ? i_animation.styles[k].value : ''

          if (i_animation.addTransition !== false) {
            transitionString += i_animation.styles[k].property + ' '
            transitionString += speed + 'ms '

            if (i_animation.styles[k].easing !== undefined)
              transitionString += i_animation.styles[k].easing
          }
        }

        // Apply the transition on the elements
        if (i_animation.addTransition !== false)
          j_selector.style.transition = transitionString
      }
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Reset the timeout of the triggering delay
  // ----------------------------------------------------------------------------------------------------------------------------
  resetTimeout() {
    clearTimeout(this.delay)
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Add the no scroll to the page. The user can no longer scroll
  // ----------------------------------------------------------------------------------------------------------------------------
  addNoScroll() {
    let doc = document
    let html = doc.querySelector('html')

    if (this.overlay.options.customScroll) {
      let i, initialRight, finalRight
      let fixedElements = doc.querySelectorAll('.fixedOutOfScroll')
      let fixedElementsLength = fixedElements.length
      let scrollBarWidth = getScrollbarWidth()
      html.classList.add('noCustomScroll')
      for (i = 0; i < fixedElementsLength; i++) {
        initialRight = parseInt(getComputedStyle(fixedElements[i]).right, 10)
        finalRight = initialRight + scrollBarWidth + 'px'
        fixedElements[i].style.right = finalRight
      }
    } else {
      let scrollTop = -html.scrollTop + 'px'
      html.classList.add('noScroll')
      html.style.top = scrollTop
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Remove the no scroll to the page. The user can scroll again in the page
  // ----------------------------------------------------------------------------------------------------------------------------
  removeNoScroll() {
    let doc = document
    let html = doc.querySelector('html')

    if (this.overlay.options.goToSelector !== '') {
      if (this.overlay.options.customScroll) {
        customScroll()
      } else {
        html.classList.remove('noScroll')
        html.scrollTop = getElementOffset(this.overlay.options.goToSelector).top
      }
    } else {
      if (this.overlay.options.customScroll) {
        customScroll()
      } else {
        let scrollPosition = parseInt(html.style.top)
        html.classList.remove('noScroll')
        html.scrollTop = -scrollPosition
      }
    }

    function customScroll() {
      let i
      let fixedElements = doc.querySelectorAll('.fixedOutOfScroll')
      let fixedElementsLength = fixedElements.length
      html.classList.remove('noCustomScroll')
      for (i = 0; i < fixedElementsLength; i++)
        fixedElements[i].style.right = ''
    }
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Destroy the overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  destroy() {

    // Remove the instance from the Variable Overlay.instance
    const index = Overlay.instances.indexOf(this)
    if (index > -1) {
      Overlay.instances.splice(index, 1)
    }

    // Remove the instance from the window object
    if (this.overlay.options.global)
      window.overlay[this.overlay.name] = undefined

    // Destroy all events
    if (getObjectSize(this.overlay.click.buttons) > 0)
      this.clickEvent.destroy()
    if (getObjectSize(this.overlay.mouseover.buttons) > 0)
      this.mouseoverEvent.destroy()
    if (getObjectSize(this.overlay.timeout.buttons) > 0 && document.querySelectorAll('#' + this.overlay.structure.id).length != 0)
      this.timeoutEvent.destroy()
    if (this.overlay.options.closeOnResize)
      this.resizeEvent.destroy()
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Destroy all overlays
  // ----------------------------------------------------------------------------------------------------------------------------
  static destroyAll() {
    let i
    let overlaysLength = Overlay.instances.length

    for (i=0; i<overlaysLength; i++) {
      if (Overlay.instances[i].getStatus() === 'open')
        Overlay.instances[i].close()

      if (Overlay.instances[i].overlay.options.global)
        window.overlay[Overlay.instances[i].overlay.name] = undefined

      if (getObjectSize(Overlay.instances[i].overlay.click.buttons) > 0)
        Overlay.instances[i].clickEvent.destroy()

      if (getObjectSize(Overlay.instances[i].overlay.mouseover.buttons) > 0)
        Overlay.instances[i].mouseoverEvent.destroy()

      if (getObjectSize(Overlay.instances[i].overlay.timeout.buttons) > 0 && document.querySelectorAll('#' + Overlay.instances[i].overlay.structure.id).length != 0)
        Overlay.instances[i].timeoutEvent.destroy()

      if (Overlay.instances[i].overlay.options.closeOnResize)
        Overlay.instances[i].resizeEvent.destroy()
    }

    Overlay.instances = []
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Return the overlay
  // ----------------------------------------------------------------------------------------------------------------------------
  static getOverlay(overlay) {
    let i
    let overlaysLength = Overlay.instances.length
    let overlayObj

    for (i=0; i<overlaysLength; i++) {
      if (Overlay.instances[i].overlay.name === overlay)
        overlayObj = Overlay.instances[i]
    }

    return overlayObj
  }


  // ----------------------------------------------------------------------------------------------------------------------------
  // Return the status
  // ----------------------------------------------------------------------------------------------------------------------------
  getStatus() {
    return this.status
  }
}

Overlay.instances = []


